import React, { useState } from 'react'
import {
  useSearchVehiclesQuery,
  DealershipVehicleSortOrder,
  DealershipVehicleSortBy,
} from '../../generated/graphql'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { formatMoney } from '../../util/money'
import { formatNumber } from '../../util/number'
import HeaderAppBar from '../Header/AppBar'
import loadingImg from '../../loading.gif'
import noImage from '../../noImage.jpg'
import RequestEmailDialog from '../Dialog/RequestEmailDialog'
import { SearchVehicle } from '../types'

function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min) //The maximum is inclusive and the minimum is inclusive
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      color: theme.palette.text.secondary,
      backgroundColor: 'rgba(265,265,265,.4)',
    },
    card: {},
    media: {
      height: 0,
      paddingTop: '75%', // 16:9
    },
    mediaSkeleton: {
      height: 0,
      paddingTop: '75%', // 16:9
      backgroundSize: 'auto',
    },
    rigthGrid: {
      textAlign: 'right',
    },
    links: {
      color: 'blue',
    },
  })
)

function VehiclesList() {
  const [make, setMake] = useState<string | null>(null)
  const { data, loading } = useSearchVehiclesQuery({
    variables: {
      size: 25,
      sortOrder: DealershipVehicleSortOrder.Desc,
      sortBy: DealershipVehicleSortBy.Price,
      make,
    },
  })
  const [requestEmailForDealership, setRequestEmailForDealership] =
    React.useState<SearchVehicle | null>(null)
  const classes = useStyles()

  const quickLinks = (
    <Box padding={2}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setMake('Ford')
            }}
          >
            Ford
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setMake('Chevrolet')
            }}
          >
            Chevy
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setMake('Ram')
            }}
          >
            Ram
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setMake('GMC')
            }}
          >
            GMC
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setMake('Jeep')
            }}
          >
            Jeep
          </Button>
        </Grid>
      </Grid>
    </Box>
  )

  if (loading) {
    return (
      <>
        <HeaderAppBar />
        {quickLinks}
        <Box padding={5}>
          <Grid container spacing={3}>
            {Array.from({ length: 20 }, (v, i) => {
              return (
                <Grid item sm={6} key={`loading-${i}`}>
                  <Paper elevation={3} className={classes.paper}>
                    <Card>
                      <CardActionArea>
                        <CardMedia
                          className={classes.mediaSkeleton}
                          image={loadingImg}
                          title="loading"
                        />
                        <Box padding={1.5}>
                          <Typography variant="h5" gutterBottom>
                            <Box fontWeight="bold">
                              <Skeleton
                                animation="wave"
                                width={`${getRandomIntInclusive(30, 50)}%`}
                              />
                            </Box>
                            <Grid container>
                              <Grid item sm={6}>
                                <Typography variant="h6">
                                  <Skeleton
                                    animation="wave"
                                    width={`${getRandomIntInclusive(60, 80)}%`}
                                  />
                                </Typography>
                                <Typography variant="body1">
                                  <Skeleton
                                    animation="wave"
                                    width={`${getRandomIntInclusive(55, 85)}%`}
                                  />
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                alignContent="flex-end"
                                alignItems="flex-end"
                                justify="flex-end"
                                className={classes.rigthGrid}
                              >
                                <Typography variant="body1">
                                  <Skeleton
                                    animation="wave"
                                    width={`${getRandomIntInclusive(65, 90)}%`}
                                  />
                                </Typography>
                                <Typography variant="body1">
                                  <Skeleton
                                    animation="wave"
                                    width={`${getRandomIntInclusive(55, 85)}%`}
                                  />
                                </Typography>
                              </Grid>
                              <Grid item sm={6}>
                                <Typography variant="body1">
                                  <Skeleton
                                    animation="wave"
                                    width={`${getRandomIntInclusive(55, 85)}%`}
                                  />
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                sm={6}
                                alignContent="flex-end"
                                alignItems="flex-end"
                                justify="flex-end"
                                className={classes.rigthGrid}
                              >
                                <Typography variant="body1">
                                  <Skeleton
                                    animation="wave"
                                    width={`${getRandomIntInclusive(55, 85)}%`}
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                          </Typography>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Paper>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </>
    )
  }

  const vehicles = data?.vehiclesBySearchCriteria.data ?? []

  return (
    <>
      <HeaderAppBar />
      {quickLinks}
      <RequestEmailDialog
        vehicle={requestEmailForDealership}
        handleClose={() => setRequestEmailForDealership(null)}
      />
      <Box padding={5}>
        <Grid container spacing={3}>
          {vehicles.map((v, i) => {
            if (!v) {
              return null
            }
            const vehicle = { ...v }
            const firstImage =
              (Array.isArray(vehicle.imageUrls) && vehicle.imageUrls[0]) ||
              noImage
            const title = `${vehicle.year || ''} ${vehicle.make || ''} ${
              vehicle.model || ''
            } ${vehicle.trim || ''} ${vehicle.drivetrain || ''}`

            return (
              <Grid item sm={6} key={`${vehicle.vin}-${i}`}>
                <Paper elevation={3} className={classes.paper}>
                  <Card>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={firstImage}
                        title={title}
                      />
                      <Box padding={1.5}>
                        <Typography variant="h5" gutterBottom>
                          <Box fontWeight="bold">{title}</Box>
                          <Grid container>
                            <Grid
                              item
                              sm={6}
                              alignContent="flex-end"
                              alignItems="flex-end"
                              justify="flex-end"
                            >
                              <Typography variant="h6">
                                {formatMoney(vehicle.price)}
                              </Typography>
                              <Typography variant="body1">
                                {typeof vehicle.mileage === 'number'
                                  ? `${formatNumber(vehicle.mileage)} mi`
                                  : 'Miles: unknown'}
                              </Typography>
                            </Grid>
                            {vehicle?.dealership ? (
                              <Grid
                                item
                                sm={6}
                                alignContent="flex-end"
                                alignItems="flex-end"
                                justify="flex-end"
                                className={classes.rigthGrid}
                              >
                                <Typography variant="body1">
                                  {vehicle?.dealership?.name}
                                </Typography>
                                <Typography variant="body1">
                                  {vehicle?.dealership?.address?.city} {', '}{' '}
                                  {vehicle?.dealership?.address?.state}
                                </Typography>
                              </Grid>
                            ) : null}
                            <Grid
                              item
                              alignContent="flex-end"
                              alignItems="flex-end"
                              justify="flex-end"
                              sm={6}
                            >
                              <Typography variant="body1">
                                <Link
                                  className={classes.links}
                                  href={`tel:${vehicle.dealership?.phoneNumber}`}
                                >
                                  {vehicle.dealership?.phoneNumber}
                                </Link>
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              sm={6}
                              alignContent="flex-end"
                              alignItems="flex-end"
                              justify="flex-end"
                              className={classes.rigthGrid}
                            >
                              <Typography variant="body1">
                                <Link
                                  className={classes.links}
                                  onClick={() => {
                                    setRequestEmailForDealership(vehicle)
                                  }}
                                >
                                  Request Information
                                </Link>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </>
  )
}

export default VehiclesList
