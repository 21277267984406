import React from 'react'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { SearchVehicle } from '../types'
import { Grid, Box } from '@material-ui/core'
import TextInput from '../Form/TextInput'
import { Formik, Form, FormikErrors, Field, FormikProps } from 'formik'
// import ky from 'ky'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

interface RequestEmailDialogProps {
  handleClose: () => unknown
  vehicle?: SearchVehicle | null
}

const RequestInfoForm = () => {
  return (
    <Form>
      <Grid container>
        <Grid sm={6} item>
          <Box padding={1}>
            <TextInput
              name="firstName"
              inputProps={{ placeholder: 'First Name' }}
            />
          </Box>
        </Grid>
        <Grid sm={6} item>
          <Box padding={1}>
            <TextInput
              name="lastName"
              inputProps={{ placeholder: 'Last Name' }}
            />
          </Box>
        </Grid>
        <Grid sm={6} item>
          <Box padding={1}>
            <TextInput name="email" inputProps={{ placeholder: 'email' }} />
          </Box>
        </Grid>
        <Grid sm={6} item>
          <Box padding={1}>
            <TextInput
              name="phone"
              inputProps={{ placeholder: 'Phone Number' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  )
}

export default function RequestEmailDialog({
  vehicle,
  handleClose,
}: RequestEmailDialogProps) {
  return (
    <Formik
      onSubmit={async (_values) => {
        // const json = await ky.post(
        //   `api/email?vin=${vehicle?.vin}&firstName=${values.firstName}&lastName=${values.lastName}&email=${values.email}&phoneNumber=${values.phone}`
        // )
        // TODO - success on request info
        // TODO - save info in local storage
        // console.log(json);
      }}
      initialValues={{ firstName: '', lastName: '', email: '', phone: '' }}
      validateOnMount
      validate={(values) => {
        const errors: FormikErrors<any> = {}
        if (!values.firstName) {
          errors.firstName = 'First Name is required'
        }

        if (!values.lastName) {
          errors.firstName = 'Last Name is required'
        }

        if (!values.email) {
          errors.email = 'Email is required'
        }

        if (!values.phone) {
          errors.phone = 'Phone number is required'
        }

        return errors
      }}
    >
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={vehicle !== null}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Request Information
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            In order to request more information for the {vehicle?.year}{' '}
            {vehicle?.make} {vehicle?.model} please fill out the information
            below.
          </Typography>
          <RequestInfoForm />
        </DialogContent>
        <DialogActions>
          <Field>
            {({ form }: { form: FormikProps<any> }) => {
              console.log(form)
              return (
                <Button
                  autoFocus
                  onClick={handleClose}
                  disabled={Object.keys(form.errors).length > 0}
                  color="primary"
                >
                  Save changes
                </Button>
              )
            }}
          </Field>
        </DialogActions>
      </Dialog>
    </Formik>
  )
}
