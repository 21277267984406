import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  Time: any
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any
}

/** 'ComingSoonSubmission' input values */
export type ComingSoonSubmissionInput = {
  email?: Maybe<Scalars['String']>
}

/** 'DealershipAddress' input values */
export type DealershipAddressInput = {
  line1: Scalars['String']
  line2?: Maybe<Scalars['String']>
  city: Scalars['String']
  state: DealershipState
  postalCode: Scalars['String']
  country: Scalars['String']
}

/** Allow manipulating the relationship between the types 'Dealership' and 'DealershipAddress' using the field 'Dealership.address'. */
export type DealershipAddressRelation = {
  /** Create a document of type 'DealershipAddress' and associate it with the current document. */
  create?: Maybe<DealershipAddressInput>
  /** Connect a document of type 'DealershipAddress' with the current document using its ID. */
  connect?: Maybe<Scalars['ID']>
  /** If true, disconnects this document from 'DealershipAddress' */
  disconnect?: Maybe<Scalars['Boolean']>
}

/** Allow manipulating the relationship between the types 'DealershipContact' and 'Dealership' using the field 'DealershipContact.dealership'. */
export type DealershipContactDealershipRelation = {
  /** Create a document of type 'Dealership' and associate it with the current document. */
  create?: Maybe<DealershipInput>
  /** Connect a document of type 'Dealership' with the current document using its ID. */
  connect?: Maybe<Scalars['ID']>
  /** If true, disconnects this document from 'Dealership' */
  disconnect?: Maybe<Scalars['Boolean']>
}

/** 'DealershipContact' input values */
export type DealershipContactInput = {
  dealershipId?: Maybe<Scalars['ID']>
  name: Scalars['String']
  email: Scalars['String']
  role: Scalars['String']
  type?: Maybe<DealershipContactType>
  dealership?: Maybe<DealershipContactDealershipRelation>
}

/** Allow manipulating the relationship between the types 'Dealership' and 'DealershipContact'. */
export type DealershipContactsRelation = {
  /** Create one or more documents of type 'DealershipContact' and associate them with the current document. */
  create?: Maybe<Array<Maybe<DealershipContactInput>>>
  /** Connect one or more documents of type 'DealershipContact' with the current document using their IDs. */
  connect?: Maybe<Array<Maybe<Scalars['ID']>>>
  /** Disconnect the given documents of type 'DealershipContact' from the current document using their IDs. */
  disconnect?: Maybe<Array<Maybe<Scalars['ID']>>>
}

/** 'DealershipContract' input values */
export type DealershipContractInput = {
  createdAt: Scalars['Time']
  updatedAt?: Maybe<Scalars['Time']>
  numberOfVehiclesRange: Scalars['String']
  numberOfVehiclesPromoted: Scalars['String']
  numberOfMonthsWithDiscountedRate?: Maybe<Scalars['Int']>
  percentageOffFromInvoice?: Maybe<Scalars['Int']>
}

/** Allow manipulating the relationship between the types 'Dealership' and 'DealershipContract' using the field 'Dealership.contract'. */
export type DealershipContractRelation = {
  /** Create a document of type 'DealershipContract' and associate it with the current document. */
  create?: Maybe<DealershipContractInput>
  /** Connect a document of type 'DealershipContract' with the current document using its ID. */
  connect?: Maybe<Scalars['ID']>
}

/** Allow manipulating the relationship between the types 'DealershipFeedRun' and 'Dealership'. */
export type DealershipFeedRunDealershipsRelation = {
  /** Create one or more documents of type 'Dealership' and associate them with the current document. */
  create?: Maybe<Array<Maybe<DealershipInput>>>
  /** Connect one or more documents of type 'Dealership' with the current document using their IDs. */
  connect?: Maybe<Array<Maybe<Scalars['ID']>>>
  /** Disconnect the given documents of type 'Dealership' from the current document using their IDs. */
  disconnect?: Maybe<Array<Maybe<Scalars['ID']>>>
}

/** 'DealershipFeedRun' input values */
export type DealershipFeedRunInput = {
  filesProcessed: Scalars['Int']
  vehiclesProcessed: Scalars['Int']
  dateProcessed: Scalars['Time']
  durationOfFeed: Scalars['Float']
  hadErrors?: Maybe<Scalars['Boolean']>
  vehiclesAdded: Scalars['Int']
  vehiclesDeactivated: Scalars['Int']
  vehiclesActivated: Scalars['Int']
  dealerships?: Maybe<DealershipFeedRunDealershipsRelation>
}

/** Allow manipulating the relationship between the types 'Dealership' and 'DealershipFeedRun'. */
export type DealershipFeedRunsRelation = {
  /** Create one or more documents of type 'DealershipFeedRun' and associate them with the current document. */
  create?: Maybe<Array<Maybe<DealershipFeedRunInput>>>
  /** Connect one or more documents of type 'DealershipFeedRun' with the current document using their IDs. */
  connect?: Maybe<Array<Maybe<Scalars['ID']>>>
  /** Disconnect the given documents of type 'DealershipFeedRun' from the current document using their IDs. */
  disconnect?: Maybe<Array<Maybe<Scalars['ID']>>>
}

/** 'Dealership' input values */
export type DealershipInput = {
  name: Scalars['String']
  address?: Maybe<DealershipAddressRelation>
  phoneNumber: Scalars['String']
  contract?: Maybe<DealershipContractRelation>
  contacts?: Maybe<DealershipContactsRelation>
  inventory?: Maybe<DealershipInventoryRelation>
  feedRuns?: Maybe<DealershipFeedRunsRelation>
  metrics?: Maybe<DealershipMetricsRelation>
}

/** Allow manipulating the relationship between the types 'Dealership' and 'DealershipVehicle'. */
export type DealershipInventoryRelation = {
  /** Create one or more documents of type 'DealershipVehicle' and associate them with the current document. */
  create?: Maybe<Array<Maybe<DealershipVehicleInput>>>
  /** Connect one or more documents of type 'DealershipVehicle' with the current document using their IDs. */
  connect?: Maybe<Array<Maybe<Scalars['ID']>>>
  /** Disconnect the given documents of type 'DealershipVehicle' from the current document using their IDs. */
  disconnect?: Maybe<Array<Maybe<Scalars['ID']>>>
}

/** 'DealershipMetrics' input values */
export type DealershipMetricsInput = {
  calls: Scalars['Int']
  emails: Scalars['Int']
  impressions: Scalars['Int']
  saves: Scalars['Int']
}

/** Allow manipulating the relationship between the types 'Dealership' and 'DealershipMetrics' using the field 'Dealership.metrics'. */
export type DealershipMetricsRelation = {
  /** Create a document of type 'DealershipMetrics' and associate it with the current document. */
  create?: Maybe<DealershipMetricsInput>
  /** Connect a document of type 'DealershipMetrics' with the current document using its ID. */
  connect?: Maybe<Scalars['ID']>
  /** If true, disconnects this document from 'DealershipMetrics' */
  disconnect?: Maybe<Scalars['Boolean']>
}

/** Allow manipulating the relationship between the types 'DealershipVehicle' and 'Dealership' using the field 'DealershipVehicle.dealership'. */
export type DealershipVehicleDealershipRelation = {
  /** Create a document of type 'Dealership' and associate it with the current document. */
  create?: Maybe<DealershipInput>
  /** Connect a document of type 'Dealership' with the current document using its ID. */
  connect?: Maybe<Scalars['ID']>
  /** If true, disconnects this document from 'Dealership' */
  disconnect?: Maybe<Scalars['Boolean']>
}

/** 'DealershipVehicle' input values */
export type DealershipVehicleInput = {
  created: Scalars['Time']
  dealershipId?: Maybe<Scalars['ID']>
  dealership?: Maybe<DealershipVehicleDealershipRelation>
  dealerId: Scalars['String']
  vin: Scalars['String']
  description?: Maybe<Scalars['String']>
  year: Scalars['Int']
  make: Scalars['String']
  model: Scalars['String']
  trim?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  fuel?: Maybe<Scalars['String']>
  stock?: Maybe<Scalars['String']>
  price: Scalars['Int']
  mileage: Scalars['Int']
  drivetrain?: Maybe<Scalars['String']>
  certified?: Maybe<Scalars['Boolean']>
  exteriorColor?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  imageUrls?: Maybe<Array<Scalars['String']>>
  trans?: Maybe<Scalars['String']>
  comments?: Maybe<Scalars['String']>
  valueAddedOptions?: Maybe<Scalars['String']>
  condition?: Maybe<Scalars['String']>
  engine?: Maybe<Scalars['String']>
  drive?: Maybe<Scalars['String']>
  mpgCity?: Maybe<Scalars['Int']>
  mpgHighway?: Maybe<Scalars['Int']>
  metrics?: Maybe<DealershipVehicleMetricsRelation>
  active?: Maybe<Scalars['Boolean']>
  display?: Maybe<Scalars['Boolean']>
  featured?: Maybe<Scalars['Boolean']>
  postalCode?: Maybe<Scalars['String']>
}

/** Allow manipulating the relationship between the types 'DealershipVehicle' and 'DealershipMetrics' using the field 'DealershipVehicle.metrics'. */
export type DealershipVehicleMetricsRelation = {
  /** Create a document of type 'DealershipMetrics' and associate it with the current document. */
  create?: Maybe<DealershipMetricsInput>
  /** Connect a document of type 'DealershipMetrics' with the current document using its ID. */
  connect?: Maybe<Scalars['ID']>
  /** If true, disconnects this document from 'DealershipMetrics' */
  disconnect?: Maybe<Scalars['Boolean']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Create a new document in the collection of 'DealershipContract' */
  createDealershipContract: DealershipContract
  /** Create a new document in the collection of 'DealershipMetrics' */
  createDealershipMetrics: DealershipMetrics
  /** Create a new document in the collection of 'DealershipVehicle' */
  createDealershipVehicle: DealershipVehicle
  /** Create a new document in the collection of 'DealershipAddress' */
  createDealershipAddress: DealershipAddress
  /** Create a new document in the collection of 'DealershipFeedRun' */
  createDealershipFeedRun: DealershipFeedRun
  /** Update an existing document in the collection of 'DealershipContact' */
  updateDealershipContact?: Maybe<DealershipContact>
  /** Create a new document in the collection of 'DealershipContact' */
  createDealershipContact: DealershipContact
  /** Update an existing document in the collection of 'DealershipFeedRun' */
  updateDealershipFeedRun?: Maybe<DealershipFeedRun>
  /** Delete an existing document in the collection of 'DealershipFeedRun' */
  deleteDealershipFeedRun?: Maybe<DealershipFeedRun>
  /** Create a new document in the collection of 'ComingSoonSubmission' */
  createComingSoonSubmission: ComingSoonSubmission
  /** Delete an existing document in the collection of 'DealershipMetrics' */
  deleteDealershipMetrics?: Maybe<DealershipMetrics>
  /** Update an existing document in the collection of 'DealershipContract' */
  updateDealershipContract?: Maybe<DealershipContract>
  /** Delete an existing document in the collection of 'DealershipContact' */
  deleteDealershipContact?: Maybe<DealershipContact>
  /** Update an existing document in the collection of 'DealershipAddress' */
  updateDealershipAddress?: Maybe<DealershipAddress>
  /** Delete an existing document in the collection of 'ComingSoonSubmission' */
  deleteComingSoonSubmission?: Maybe<ComingSoonSubmission>
  /** Update an existing document in the collection of 'DealershipVehicle' */
  updateDealershipVehicle?: Maybe<DealershipVehicle>
  /** Delete an existing document in the collection of 'Dealership' */
  deleteDealership?: Maybe<Dealership>
  /** Create a new document in the collection of 'Dealership' */
  createDealership: Dealership
  /** Delete an existing document in the collection of 'DealershipAddress' */
  deleteDealershipAddress?: Maybe<DealershipAddress>
  /** Update an existing document in the collection of 'Dealership' */
  updateDealership?: Maybe<Dealership>
  /** Update an existing document in the collection of 'ComingSoonSubmission' */
  updateComingSoonSubmission?: Maybe<ComingSoonSubmission>
  /** Delete an existing document in the collection of 'DealershipVehicle' */
  deleteDealershipVehicle?: Maybe<DealershipVehicle>
  /** Update an existing document in the collection of 'DealershipMetrics' */
  updateDealershipMetrics?: Maybe<DealershipMetrics>
  /** Delete an existing document in the collection of 'DealershipContract' */
  deleteDealershipContract?: Maybe<DealershipContract>
}

export type MutationCreateDealershipContractArgs = {
  data: DealershipContractInput
}

export type MutationCreateDealershipMetricsArgs = {
  data: DealershipMetricsInput
}

export type MutationCreateDealershipVehicleArgs = {
  data: DealershipVehicleInput
}

export type MutationCreateDealershipAddressArgs = {
  data: DealershipAddressInput
}

export type MutationCreateDealershipFeedRunArgs = {
  data: DealershipFeedRunInput
}

export type MutationUpdateDealershipContactArgs = {
  id: Scalars['ID']
  data: DealershipContactInput
}

export type MutationCreateDealershipContactArgs = {
  data: DealershipContactInput
}

export type MutationUpdateDealershipFeedRunArgs = {
  id: Scalars['ID']
  data: DealershipFeedRunInput
}

export type MutationDeleteDealershipFeedRunArgs = {
  id: Scalars['ID']
}

export type MutationCreateComingSoonSubmissionArgs = {
  data: ComingSoonSubmissionInput
}

export type MutationDeleteDealershipMetricsArgs = {
  id: Scalars['ID']
}

export type MutationUpdateDealershipContractArgs = {
  id: Scalars['ID']
  data: DealershipContractInput
}

export type MutationDeleteDealershipContactArgs = {
  id: Scalars['ID']
}

export type MutationUpdateDealershipAddressArgs = {
  id: Scalars['ID']
  data: DealershipAddressInput
}

export type MutationDeleteComingSoonSubmissionArgs = {
  id: Scalars['ID']
}

export type MutationUpdateDealershipVehicleArgs = {
  id: Scalars['ID']
  data: DealershipVehicleInput
}

export type MutationDeleteDealershipArgs = {
  id: Scalars['ID']
}

export type MutationCreateDealershipArgs = {
  data: DealershipInput
}

export type MutationDeleteDealershipAddressArgs = {
  id: Scalars['ID']
}

export type MutationUpdateDealershipArgs = {
  id: Scalars['ID']
  data: DealershipInput
}

export type MutationUpdateComingSoonSubmissionArgs = {
  id: Scalars['ID']
  data: ComingSoonSubmissionInput
}

export type MutationDeleteDealershipVehicleArgs = {
  id: Scalars['ID']
}

export type MutationUpdateDealershipMetricsArgs = {
  id: Scalars['ID']
  data: DealershipMetricsInput
}

export type MutationDeleteDealershipContractArgs = {
  id: Scalars['ID']
}

export type ComingSoonSubmission = {
  __typename?: 'ComingSoonSubmission'
  /** The document's ID. */
  _id: Scalars['ID']
  /** The document's timestamp. */
  _ts: Scalars['Long']
  email?: Maybe<Scalars['String']>
}

export type Dealership = {
  __typename?: 'Dealership'
  inventory: DealershipVehiclePage
  name: Scalars['String']
  phoneNumber: Scalars['String']
  /** The document's ID. */
  _id: Scalars['ID']
  contacts: DealershipContactPage
  feedRuns: DealershipFeedRunPage
  address?: Maybe<DealershipAddress>
  metrics?: Maybe<DealershipMetrics>
  contract: DealershipContract
  /** The document's timestamp. */
  _ts: Scalars['Long']
}

export type DealershipInventoryArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
}

export type DealershipContactsArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
}

export type DealershipFeedRunsArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
}

export type DealershipAddress = {
  __typename?: 'DealershipAddress'
  city: Scalars['String']
  state: DealershipState
  /** The document's ID. */
  _id: Scalars['ID']
  country: Scalars['String']
  line1: Scalars['String']
  postalCode: Scalars['String']
  line2?: Maybe<Scalars['String']>
  /** The document's timestamp. */
  _ts: Scalars['Long']
}

export type DealershipContact = {
  __typename?: 'DealershipContact'
  name: Scalars['String']
  email: Scalars['String']
  role: Scalars['String']
  /** The document's ID. */
  _id: Scalars['ID']
  dealershipId?: Maybe<Scalars['ID']>
  dealership?: Maybe<Dealership>
  type?: Maybe<DealershipContactType>
  /** The document's timestamp. */
  _ts: Scalars['Long']
}

/** The pagination object for elements of type 'DealershipContact'. */
export type DealershipContactPage = {
  __typename?: 'DealershipContactPage'
  /** The elements of type 'DealershipContact' in this page. */
  data: Array<Maybe<DealershipContact>>
  /** A cursor for elements coming after the current page. */
  after?: Maybe<Scalars['String']>
  /** A cursor for elements coming before the current page. */
  before?: Maybe<Scalars['String']>
}

export enum DealershipContactType {
  Cc = 'CC',
  Bcc = 'BCC',
  Main = 'MAIN',
}

export type DealershipContract = {
  __typename?: 'DealershipContract'
  percentageOffFromInvoice?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['Time']>
  numberOfVehiclesRange: Scalars['String']
  /** The document's ID. */
  _id: Scalars['ID']
  numberOfMonthsWithDiscountedRate?: Maybe<Scalars['Int']>
  numberOfVehiclesPromoted: Scalars['String']
  createdAt: Scalars['Time']
  /** The document's timestamp. */
  _ts: Scalars['Long']
}

export type DealershipFeedRun = {
  __typename?: 'DealershipFeedRun'
  durationOfFeed: Scalars['Float']
  dateProcessed: Scalars['Time']
  vehiclesAdded: Scalars['Int']
  /** The document's ID. */
  _id: Scalars['ID']
  vehiclesActivated: Scalars['Int']
  vehiclesDeactivated: Scalars['Int']
  filesProcessed: Scalars['Int']
  hadErrors?: Maybe<Scalars['Boolean']>
  dealerships: DealershipPage
  vehiclesProcessed: Scalars['Int']
  /** The document's timestamp. */
  _ts: Scalars['Long']
}

export type DealershipFeedRunDealershipsArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
}

/** The pagination object for elements of type 'DealershipFeedRun'. */
export type DealershipFeedRunPage = {
  __typename?: 'DealershipFeedRunPage'
  /** The elements of type 'DealershipFeedRun' in this page. */
  data: Array<Maybe<DealershipFeedRun>>
  /** A cursor for elements coming after the current page. */
  after?: Maybe<Scalars['String']>
  /** A cursor for elements coming before the current page. */
  before?: Maybe<Scalars['String']>
}

export type DealershipMetrics = {
  __typename?: 'DealershipMetrics'
  impressions: Scalars['Int']
  saves: Scalars['Int']
  /** The document's ID. */
  _id: Scalars['ID']
  calls: Scalars['Int']
  emails: Scalars['Int']
  /** The document's timestamp. */
  _ts: Scalars['Long']
}

/** The pagination object for elements of type 'Dealership'. */
export type DealershipPage = {
  __typename?: 'DealershipPage'
  /** The elements of type 'Dealership' in this page. */
  data: Array<Maybe<Dealership>>
  /** A cursor for elements coming after the current page. */
  after?: Maybe<Scalars['String']>
  /** A cursor for elements coming before the current page. */
  before?: Maybe<Scalars['String']>
}

export enum DealershipState {
  Al = 'AL',
  Ak = 'AK',
  As = 'AS',
  Az = 'AZ',
  Ar = 'AR',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  De = 'DE',
  Dc = 'DC',
  Fm = 'FM',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ia = 'IA',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Me = 'ME',
  Mh = 'MH',
  Md = 'MD',
  Ma = 'MA',
  Mi = 'MI',
  Mn = 'MN',
  Ms = 'MS',
  Mo = 'MO',
  Mt = 'MT',
  Ne = 'NE',
  Nv = 'NV',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Ny = 'NY',
  Nc = 'NC',
  Nd = 'ND',
  Mp = 'MP',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pw = 'PW',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Vt = 'VT',
  Vi = 'VI',
  Va = 'VA',
  Wa = 'WA',
  Wv = 'WV',
  Wi = 'WI',
  Wy = 'WY',
}

export type DealershipVehicle = {
  __typename?: 'DealershipVehicle'
  drive?: Maybe<Scalars['String']>
  stock?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  dealerId: Scalars['String']
  trans?: Maybe<Scalars['String']>
  imageUrls?: Maybe<Array<Scalars['String']>>
  model: Scalars['String']
  mpgCity?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  mileage: Scalars['Int']
  /** The document's ID. */
  _id: Scalars['ID']
  dealershipId?: Maybe<Scalars['ID']>
  drivetrain?: Maybe<Scalars['String']>
  price: Scalars['Int']
  featured?: Maybe<Scalars['Boolean']>
  year: Scalars['Int']
  dealership?: Maybe<Dealership>
  certified?: Maybe<Scalars['Boolean']>
  fuel?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  condition?: Maybe<Scalars['String']>
  exteriorColor?: Maybe<Scalars['String']>
  vin: Scalars['String']
  valueAddedOptions?: Maybe<Scalars['String']>
  make: Scalars['String']
  metrics?: Maybe<DealershipMetrics>
  mpgHighway?: Maybe<Scalars['Int']>
  trim?: Maybe<Scalars['String']>
  interiorColor?: Maybe<Scalars['String']>
  engine?: Maybe<Scalars['String']>
  created: Scalars['Time']
  display?: Maybe<Scalars['Boolean']>
  comments?: Maybe<Scalars['String']>
  active?: Maybe<Scalars['Boolean']>
  /** The document's timestamp. */
  _ts: Scalars['Long']
}

/** The pagination object for elements of type 'DealershipVehicle'. */
export type DealershipVehiclePage = {
  __typename?: 'DealershipVehiclePage'
  /** The elements of type 'DealershipVehicle' in this page. */
  data: Array<Maybe<DealershipVehicle>>
  /** A cursor for elements coming after the current page. */
  after?: Maybe<Scalars['String']>
  /** A cursor for elements coming before the current page. */
  before?: Maybe<Scalars['String']>
}

export enum DealershipVehicleSortBy {
  Price = 'price',
  Created = 'created',
}

export enum DealershipVehicleSortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type Query = {
  __typename?: 'Query'
  /** Find a document from the collection of 'DealershipAddress' by its id. */
  findDealershipAddressByID?: Maybe<DealershipAddress>
  /** Find a document from the collection of 'DealershipVehicle' by its id. */
  findDealershipVehicleByID?: Maybe<DealershipVehicle>
  /** Find a document from the collection of 'DealershipContract' by its id. */
  findDealershipContractByID?: Maybe<DealershipContract>
  vehiclesByDealershipId: DealershipVehiclePage
  /** Find a document from the collection of 'ComingSoonSubmission' by its id. */
  findComingSoonSubmissionByID?: Maybe<ComingSoonSubmission>
  /** Find a document from the collection of 'DealershipMetrics' by its id. */
  findDealershipMetricsByID?: Maybe<DealershipMetrics>
  allVehicles: DealershipVehiclePage
  allDealershipFeedRuns: DealershipFeedRunPage
  /** Find a document from the collection of 'Dealership' by its id. */
  findDealershipByID?: Maybe<Dealership>
  vehiclesBySearchCriteria: QueryVehiclesBySearchCriteriaPage
  dealershipsByName: DealershipPage
  /** Find a document from the collection of 'DealershipFeedRun' by its id. */
  findDealershipFeedRunByID?: Maybe<DealershipFeedRun>
  /** Find a document from the collection of 'DealershipContact' by its id. */
  findDealershipContactByID?: Maybe<DealershipContact>
  allDealershipFeedRunsByDateProcessed: DealershipFeedRunPage
  allDealerships: DealershipPage
  vehiclesByActiveFlag: DealershipVehiclePage
}

export type QueryFindDealershipAddressByIdArgs = {
  id: Scalars['ID']
}

export type QueryFindDealershipVehicleByIdArgs = {
  id: Scalars['ID']
}

export type QueryFindDealershipContractByIdArgs = {
  id: Scalars['ID']
}

export type QueryVehiclesByDealershipIdArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
  dealershipId: Scalars['ID']
}

export type QueryFindComingSoonSubmissionByIdArgs = {
  id: Scalars['ID']
}

export type QueryFindDealershipMetricsByIdArgs = {
  id: Scalars['ID']
}

export type QueryAllVehiclesArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
}

export type QueryAllDealershipFeedRunsArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
}

export type QueryFindDealershipByIdArgs = {
  id: Scalars['ID']
}

export type QueryVehiclesBySearchCriteriaArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
  make?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
  vin?: Maybe<Scalars['String']>
  yearStart?: Maybe<Scalars['Int']>
  yearEnd?: Maybe<Scalars['Int']>
  priceStart?: Maybe<Scalars['Int']>
  priceEnd?: Maybe<Scalars['Int']>
  mileageStart?: Maybe<Scalars['Int']>
  mileageEnd?: Maybe<Scalars['Int']>
  postalCodes?: Maybe<Array<Scalars['String']>>
  sortBy: DealershipVehicleSortBy
  sortOrder: DealershipVehicleSortOrder
}

export type QueryDealershipsByNameArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type QueryFindDealershipFeedRunByIdArgs = {
  id: Scalars['ID']
}

export type QueryFindDealershipContactByIdArgs = {
  id: Scalars['ID']
}

export type QueryAllDealershipFeedRunsByDateProcessedArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
  dateProcessed: Scalars['Time']
}

export type QueryAllDealershipsArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
}

export type QueryVehiclesByActiveFlagArgs = {
  _size?: Maybe<Scalars['Int']>
  _cursor?: Maybe<Scalars['String']>
  active: Scalars['Boolean']
}

/** The pagination object for elements of type 'DealershipVehicle'. */
export type QueryVehiclesBySearchCriteriaPage = {
  __typename?: 'QueryVehiclesBySearchCriteriaPage'
  /** The elements of type 'DealershipVehicle' in this page. */
  data: Array<Maybe<DealershipVehicle>>
  /** A cursor for elements coming after the current page. */
  after?: Maybe<Scalars['String']>
  /** A cursor for elements coming before the current page. */
  before?: Maybe<Scalars['String']>
}

export type SearchVehiclesQueryVariables = Exact<{
  make?: Maybe<Scalars['String']>
  model?: Maybe<Scalars['String']>
  vin?: Maybe<Scalars['String']>
  yearStart?: Maybe<Scalars['Int']>
  yearEnd?: Maybe<Scalars['Int']>
  priceStart?: Maybe<Scalars['Int']>
  priceEnd?: Maybe<Scalars['Int']>
  mileageStart?: Maybe<Scalars['Int']>
  mileageEnd?: Maybe<Scalars['Int']>
  postalCodes?: Maybe<Array<Scalars['String']> | Scalars['String']>
  sortBy: DealershipVehicleSortBy
  sortOrder: DealershipVehicleSortOrder
  size: Scalars['Int']
  cursor?: Maybe<Scalars['String']>
}>

export type SearchVehiclesQuery = { __typename?: 'Query' } & {
  vehiclesBySearchCriteria: {
    __typename?: 'QueryVehiclesBySearchCriteriaPage'
  } & Pick<QueryVehiclesBySearchCriteriaPage, 'before' | 'after'> & {
      data: Array<
        Maybe<
          { __typename?: 'DealershipVehicle' } & Pick<
            DealershipVehicle,
            | '_id'
            | 'make'
            | 'model'
            | 'vin'
            | 'price'
            | 'description'
            | 'valueAddedOptions'
            | 'drivetrain'
            | 'condition'
            | 'mileage'
            | 'imageUrls'
            | 'year'
            | 'trim'
          > & {
              dealership?: Maybe<
                { __typename?: 'Dealership' } & Pick<
                  Dealership,
                  'name' | 'phoneNumber'
                > & {
                    address?: Maybe<
                      { __typename?: 'DealershipAddress' } & Pick<
                        DealershipAddress,
                        'line1' | 'line2' | 'city' | 'state' | 'postalCode'
                      >
                    >
                  }
              >
            }
        >
      >
    }
}

export type CreateComingSoonSubmissionMutationVariables = Exact<{
  data: ComingSoonSubmissionInput
}>

export type CreateComingSoonSubmissionMutation = { __typename?: 'Mutation' } & {
  createComingSoonSubmission: { __typename?: 'ComingSoonSubmission' } & Pick<
    ComingSoonSubmission,
    'email'
  >
}

export const SearchVehiclesDocument = gql`
  query SearchVehicles(
    $make: String
    $model: String
    $vin: String
    $yearStart: Int
    $yearEnd: Int
    $priceStart: Int
    $priceEnd: Int
    $mileageStart: Int
    $mileageEnd: Int
    $postalCodes: [String!]
    $sortBy: DealershipVehicleSortBy!
    $sortOrder: DealershipVehicleSortOrder!
    $size: Int!
    $cursor: String
  ) {
    vehiclesBySearchCriteria(
      make: $make
      model: $model
      vin: $vin
      yearStart: $yearStart
      yearEnd: $yearEnd
      priceStart: $priceStart
      priceEnd: $priceEnd
      mileageStart: $mileageStart
      mileageEnd: $mileageEnd
      postalCodes: $postalCodes
      sortBy: $sortBy
      sortOrder: $sortOrder
      _size: $size
      _cursor: $cursor
    ) {
      data {
        _id
        make
        model
        vin
        price
        description
        valueAddedOptions
        drivetrain
        condition
        mileage
        imageUrls
        year
        trim
        dealership {
          name
          phoneNumber
          address {
            line1
            line2
            city
            state
            postalCode
          }
        }
      }
      before
      after
    }
  }
`

/**
 * __useSearchVehiclesQuery__
 *
 * To run a query within a React component, call `useSearchVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVehiclesQuery({
 *   variables: {
 *      make: // value for 'make'
 *      model: // value for 'model'
 *      vin: // value for 'vin'
 *      yearStart: // value for 'yearStart'
 *      yearEnd: // value for 'yearEnd'
 *      priceStart: // value for 'priceStart'
 *      priceEnd: // value for 'priceEnd'
 *      mileageStart: // value for 'mileageStart'
 *      mileageEnd: // value for 'mileageEnd'
 *      postalCodes: // value for 'postalCodes'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      size: // value for 'size'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSearchVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchVehiclesQuery,
    SearchVehiclesQueryVariables
  >
) {
  return Apollo.useQuery<SearchVehiclesQuery, SearchVehiclesQueryVariables>(
    SearchVehiclesDocument,
    baseOptions
  )
}
export function useSearchVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchVehiclesQuery,
    SearchVehiclesQueryVariables
  >
) {
  return Apollo.useLazyQuery<SearchVehiclesQuery, SearchVehiclesQueryVariables>(
    SearchVehiclesDocument,
    baseOptions
  )
}
export type SearchVehiclesQueryHookResult = ReturnType<
  typeof useSearchVehiclesQuery
>
export type SearchVehiclesLazyQueryHookResult = ReturnType<
  typeof useSearchVehiclesLazyQuery
>
export type SearchVehiclesQueryResult = Apollo.QueryResult<
  SearchVehiclesQuery,
  SearchVehiclesQueryVariables
>
export const CreateComingSoonSubmissionDocument = gql`
  mutation CreateComingSoonSubmission($data: ComingSoonSubmissionInput!) {
    createComingSoonSubmission(data: $data) {
      email
    }
  }
`
export type CreateComingSoonSubmissionMutationFn = Apollo.MutationFunction<
  CreateComingSoonSubmissionMutation,
  CreateComingSoonSubmissionMutationVariables
>

/**
 * __useCreateComingSoonSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateComingSoonSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComingSoonSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComingSoonSubmissionMutation, { data, loading, error }] = useCreateComingSoonSubmissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateComingSoonSubmissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateComingSoonSubmissionMutation,
    CreateComingSoonSubmissionMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateComingSoonSubmissionMutation,
    CreateComingSoonSubmissionMutationVariables
  >(CreateComingSoonSubmissionDocument, baseOptions)
}
export type CreateComingSoonSubmissionMutationHookResult = ReturnType<
  typeof useCreateComingSoonSubmissionMutation
>
export type CreateComingSoonSubmissionMutationResult = Apollo.MutationResult<CreateComingSoonSubmissionMutation>
export type CreateComingSoonSubmissionMutationOptions = Apollo.BaseMutationOptions<
  CreateComingSoonSubmissionMutation,
  CreateComingSoonSubmissionMutationVariables
>
