import { useField } from 'formik'
import { TextField, TextFieldProps } from '@material-ui/core'

type TextInputProps = {
  name: string
  inputProps: TextFieldProps
}

const TextInput = ({ inputProps, name }: TextInputProps) => {
  const [field, { touched, error }] = useField({ name })

  return (
    <TextField
      size="small"
      fullWidth
      variant="outlined"
      {...inputProps}
      {...field}
      error={touched && Boolean(error)}
      helperText={touched && error}
    />
  )
}

export default TextInput
