import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import theme from '../theme'
import logo from '../logo.png'
import { useCreateComingSoonSubmissionMutation } from '../generated/graphql'
import { useState } from 'react'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'

const useStyles = makeStyles({
  h3: {
    color: theme.palette.secondary.light,
  },
  h6: {
    color: 'rgb(248, 190, 40)',
  },
  logo: {
    textAlign: 'center',
  },

  cssLabel: {
    color: `${theme.palette.secondary.light} !important`,
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `rgb(248, 190, 40) !important`,
      color: theme.palette.secondary.light,
    },
    color: theme.palette.secondary.light,
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${theme.palette.secondary.light} !important`,
  },

  inputWrapper: {
    width: '100%',
  },
  cssFocused: {},
  button: {
    backgroundColor: 'rgb(248, 190, 40)',
    height: '55px',
    fontSize: 'large',
  },
  submitted: {
    textAlign: 'center',
    color: 'rgb(248, 190, 40)',
  },
  socialIcon: {
    color: 'white',
    height: 50,
    width: 50,
  },
})

export default function ComingSoonLanding() {
  const classes = useStyles()
  const [
    creatFormSubmission,
    { loading },
  ] = useCreateComingSoonSubmissionMutation()
  const [email, setEmail] = useState<string>('')
  const [submitted, setSubmitted] = useState<boolean>(false)

  return (
    <Container maxWidth="md">
      <Box marginTop="10%">
        <Box marginBottom={4} className={classes.logo}>
          <img src={logo} alt="lifted rides logo" />
        </Box>
        <Typography variant="h3" className={classes.h3}>
          We are close to launching our marketplace
        </Typography>
        <Typography variant="h6" className={classes.h6}>
          {
            'We are close to unvieling our marketplace that will make searching and buying your next lifted ride a much easier. Enter your email to receive updates!'
          }
        </Typography>
        <Container maxWidth="sm">
          <Box marginTop={2} marginBottom={5}>
            {submitted && (
              <Box marginBottom={3}>
                <Typography variant="h6" className={classes.submitted}>
                  {'Thank you for your interest!'}
                </Typography>
              </Box>
            )}
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextField
                  disabled={submitted}
                  label="Email"
                  variant="outlined"
                  className={classes.inputWrapper}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    inputMode: 'numeric',
                  }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={async () => {
                    await creatFormSubmission({
                      variables: {
                        data: {
                          email,
                        },
                      },
                    })
                    setSubmitted(true)
                  }}
                  className={classes.button}
                  variant="contained"
                  size="large"
                  disabled={email === '' || submitted}
                >
                  {loading ? <CircularProgress /> : 'Enter'}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2} alignItems="center" justify="center">
            <Grid item xs={3}>
              <IconButton
                target="_blank"
                href="https://www.facebook.com/Liftedridescom-343932989664191"
              >
                <FacebookIcon className={classes.socialIcon} />
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <IconButton
                target="_blank"
                href="https://www.instagram.com/liftedrides/"
              >
                <InstagramIcon className={classes.socialIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Container>
  )
}
