import React from 'react'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import theme from './theme'
import ComingSoonLanding from './components/ComingSoonLanding'
import VehiclesList from './components/Vehicles/List'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

function Index() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          <Route path="/vehicles">
            <VehiclesList />
          </Route>
          <Route path="/">
            <ComingSoonLanding />
          </Route>
        </Switch>
      </ThemeProvider>
    </Router>
  )
}

export default Index
