import { makeStyles } from '@material-ui/core/styles'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import logo from '../../logo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

const AppBar = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <MuiAppBar position="static">
        <Toolbar>
          <img src={logo} alt="liftedrides logo" height={50} />
        </Toolbar>
      </MuiAppBar>
    </div>
  )
}

export default AppBar
